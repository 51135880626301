import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import JDILogin from "@/common-components-src/js/newlogin/index.newlogin";
import { areaAddress } from "@/utils/addressUtils";
import { objectToUrlParams } from "@/common-components-src/js/utils/objectToUrlParams";
import { getEdiTokenFromCookie } from "@/utils/fingerprint";
import cookieUtil from "@/utils/cookieUtils";
import isInWhiteList from "@/constants/attributionWhiteList";

const axiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_COLOR_URL,
  timeout: 10000,
  headers: { "Content-Type": "application/x-www-form-urlencoded" },
});

// 参考链接： https://joyspace.jd.com/pages/VYHKV1cZW4Xp4RPm1Q3p?block=aMvr3Q
const commonParamReq = axiosInstance.interceptors.request.use(async (config: any) => {
  config.headers.set("x-api-platform", "pc");
  config.withCredentials = true;
  // 以下为原有逻辑，url中附带额外参数，待后端接口变化后更新
  const cthrParams = config?.cthr ? { cthr: config?.cthr } : null;
  const originUrlParams = new URLSearchParams(config.urlParams);
  const originUrlSearchParams = new URLSearchParams(config.params);
  const defaultUrlSearchParams = new URLSearchParams({
    functionId: config.functionId,
    appid: "joybuyPC",
    loginType: "30",
    t: String(new Date().getTime()),
    client: "pc",
    clientVersion: "1.0.0",
    "x-api-eid-token": getEdiTokenFromCookie(),
    ...cthrParams,
    // ...urlParams
  });
  // originUrlParams
  const combinedUrlSearchParams = new URLSearchParams({
    ...Object.fromEntries(originUrlParams),
    ...Object.fromEntries(defaultUrlSearchParams),
    ...Object.fromEntries(originUrlSearchParams),
  });
  config.params = combinedUrlSearchParams;
  // 用于在本地判断的一些配置放在meta里
  config.meta = config.meta || {};
  // 以上为原有逻辑，url中附带额外参数，待后端接口变化后更新
  return config;
});

const authInterceptorRes = axiosInstance.interceptors.response.use(
  async (response) => {
    // 是否需要强制登录
    const forceIgnoreLogin = response.config?.meta?.forceIgnoreLogin;
    if (Number(response.data.code) === 401 && !forceIgnoreLogin) {
      JDILogin.getInstance().openLoginDialog(() => {});
    }

    return response;
  },
  async (err) => {
    console.log("zsf err", err);
    return Promise.reject(err);
  },
);

// todolist: 此处失败改为打点上报，待集成监控系统
const errHandleInterceptorRes = axiosInstance.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (err) => {
    console.error("api请求失败：", err);
  },
);

type Json = string | number | boolean | null | { [property: string]: Json } | Json[] | any;
interface AxiosRequestConfigWithBodyQuery<D> extends AxiosRequestConfig<D> {
  params?: Json;
  urlParams?: Json;
  cthr?: string;
}

interface getFunc {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  <T = any, R = AxiosResponse<T>, D = any>(url: string, config?: AxiosRequestConfigWithBodyQuery<D>, isCommonArea?: boolean): Promise<R>;
}
interface postFunc {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  <T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfigWithBodyQuery<D>, isCommonArea?: boolean): Promise<R>;
}

const get: getFunc = async (functionId, config, isCommonArea = true) => {
  let url = "";
  if (config?.urlParams) {
    url = `?${objectToUrlParams(config?.urlParams)}`;
  }
  if (config?.params) {
    if (isCommonArea) {
      const area = await areaAddress();
      Object.assign(config.params, { ...area });
    }
    addAttributionParams(functionId, config.params);
    config.params = {
      body: JSON.stringify(config.params),
    };
  }
  const AxiosRequestConfig = {
    ...config,
    functionId,
  };
  return axiosInstance.get(url, AxiosRequestConfig);
};
const post: postFunc = async (functionId, data, config, isCommonArea = true) => {
  //判断config.params是否传入
  if (config?.params) {
    const area = await areaAddress();
    //bodyQuery中添加area、postCode等地址公共参数
    if (isCommonArea) {
      Object.assign(config.params, { ...area });
    }
    addAttributionParams(functionId, config.params);
    config.params = {
      body: JSON.stringify(config.params),
    };
  }
  let cthrParams = config?.cthr ? { cthr: config?.cthr } : null;
  const AxiosRequestConfig = {
    ...config,
    functionId,
    ...cthrParams,
  };

  return axiosInstance.post(
    // functionId,
    "",
    data,
    AxiosRequestConfig,
  );
};

// 添加广告归因参数
const addAttributionParams = (functionId: string, params: Json) => {
  if (isInWhiteList(functionId)) {
    let attribution = cookieUtil.getCookie("_attribution");
    if (attribution) {
      Object.assign(params, { launchAttribution: attribution });
    }
  }
};

export default { get, post };
// 导出interceptor，便于特殊场景下取消interceptor, axios.interceptors.request.eject(interceptor);
export { commonParamReq, authInterceptorRes, errHandleInterceptorRes, axiosInstance };
